function modals(){
    console.log('[RUNNING MODALS]');

    // Check if .modal-global is present
    const modal = document.querySelector(".modal-global");
    if (!modal) return; // Exit if no modal is found

    // Parse the data-settings attribute
    const settingsString = modal.getAttribute("data-settings");
    if (!settingsString) return;

    let settings;
    try {
        settings = JSON.parse(decodeURIComponent(settingsString));
    }
    catch (error) {
        console.error("Failed to parse modal settings JSON:", error);
        return;
    }

    // console.log('[MODALS]', modal, settings);

    // Validate settings
    const { enabled, cookie, delay } = settings;
    if (!enabled || isNaN(cookie) || isNaN(delay)) {
        console.error("Invalid settings in modal JSON");
        return;
    }

    const COOKIE_NAME = "fc-modal-overlay";

    // Helper functions
    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    };

    const getCookie = (name) => {
        const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
        return match ? match[2] : null;
    };

    const showModal = () => {
        modal.classList.remove("hidden");
        modal.setAttribute("aria-hidden", "false");
        modal.setAttribute("tabindex", "0");
        modal.focus();
    };

    const hideModal = () => {
        modal.classList.add("hidden");
        modal.setAttribute("aria-hidden", "true");
        modal.setAttribute("tabindex", "-1");
    };

    // Check if the cookie is set
    if (getCookie(COOKIE_NAME)){
        // console.log('[MODALS COOKIE IS SET]');
        return;
    }
    else{
        // console.log('[MODALS COOKIE IS NOT SET]');
    }

    // Set the modal's initial state for accessibility
    modal.setAttribute("aria-hidden", "true");
    modal.setAttribute("tabindex", "-1");

    // Reveal the modal after the delay
    setTimeout(() => {
        // console.log('[MODALS DELAY]', (parseInt(delay, 10) * 1000));
        showModal();
        setCookie(COOKIE_NAME, "seen", parseInt(cookie, 10));
    }, parseInt(delay, 10) * 1000);

    // Handle close button click
    const closeBtn = modal.querySelector("#closeBtn");
    if (closeBtn) {
        closeBtn.addEventListener("click", (e) => {
            e.preventDefault();
            hideModal();
        });
    }
}

modals();